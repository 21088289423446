import './App.css';
import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import Careers from './pages/Careers';
function App() {
  return (
    <Router>
    <div className="App">
    <Routes>
      <Route exact path='/' element={<Home/>}/>
      <Route exact path='home' element={<Home/>}/>
      <Route exact path='services' element={<Services/>}/>
      <Route exact path='about' element={<About/>}/>
      <Route exact path='contact' element={<Contact/>}/>
      <Route exact path='careers' element={<Careers/>}/>
    </Routes>
    </div>
    </Router>
  );
}

export default App;
