import React from 'react'
import './Footer.css'
import {Link} from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <div>
<hr></hr>
<section className="ft ">
<div class="container d-flex justify-content-around">
  <div class="row align-items-start">
  
    <div class="col">
    <img src="logo-color.png" alt="" style={{"height":"120px","width":"160px","marginBottom":"10px","alignItems":"center"}}/>
     <h4 >RESHNAKS INFOTECH </h4><span style={{"fontStyle":"italic","fontSize":"16px"}}>Coding Dreams Into Reality</span><br />
     <span>At RESHNAKS INFOTECH, we don't just meet industry standards; we redefine them. We're a dynamic force that combines the very essence of innovation and expertise to sculpt digital experiences that transcend expectations and elevate businesses to unprecedented heights. Prepare to embark on a transformative journey as you discover the boundless power of our tailor-made solutions. With us, growth isn't an option; it's an inherent part of the journey, and transformation is the only destination.</span>
    </div>
    <div class="col" id='kl'>
    <img src="cus.jpg" alt="" style={{"height":"120px","width":"160px","marginBottom":"10px","alignItems":"center"}}/>
    <h4 >Contact Us</h4><br />
    <span>Email: info@reshnaks.com</span><br /><br />
    <span>Phone Number: +91 88102 78084</span><br /><br />
    <span>Address: Ahmedabad, Gujarat, India.</span>
    </div>
    </div>
    </div>
</section>


      <div className="container">

      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
    <div className="col-md-4 d-flex align-items-center">
      <Link to="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
        <svg className="bi" width="30" height="24"></svg>
      </Link>
      <span className="mb-3 mb-md-0 text-muted">© 2023 Company, Inc <img src="logo-color.png" id='fvco' alt="" /></span>
    </div>

    <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
      <li className="ms-3"><Link className="text-muted" to="https://www.linkedin.com/company/sarena-infotech"><svg className="bi" width="24" height="24"><LinkedInIcon/></svg></Link></li>
      <li className="ms-3"><Link className="text-muted" to="https://www.instagram.com/sarena_infotech/"><svg className="bi" width="24" height="24"><InstagramIcon/></svg></Link></li>
    </ul>
  </footer>

      </div>
    </div>
  )
}

export default Footer
