import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import vid from '../images/bsnes (2).mp4';
import './About.css';

const About = () => {
  return (
    <div>
      <Navbar />
      <div className="container mt-4">
      <video style={{ "height": "100vh", "width": "100%" }} controls autoPlay loop>
            <source src={vid} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        <h3 className='my-4'>"We are a visionary team of expert developers, ingenious designers, and innovative solution architects. We don't just create; we craft, shape, and deliver excellence. Your digital success is our mission."</h3>
        <p>"Guided by the Visionary Leadership of Kshitiz Sharma and Shailesh Sharma, we thrive through boundless creativity, unwavering determination, and the harmonious synergy between our exceptional team, valued clients, and delighted users. Our journey is defined by the relentless pursuit of excellence."</p>
        <section className="one my-3">
          <div className="card my-3">
            <div className="card-body1 d-flex my-4">
              <h1 className="card-title mx-5">We Are</h1>
              <p className="card-text"> RESHNAKS INFOTECH, a pioneering Software Application Development Company committed to delivering excellence. Our relentless pursuit of quality and trust in software solutions ensures lasting satisfaction and value for our clients. With an unwavering mission, we offer end-to-end solutions, fostering innovation, and upholding the highest quality standards. Join us on a journey of growth and innovation that sets new standards in the industry.</p>
            </div>
            <hr />
            <div className="card-body1 d-flex">
              <h1 className="card-title mx-4">We Make</h1>
              <p className="card-text"> At RESHNAKS INFOTECH, we are a global tech powerhouse, serving clients worldwide, from agile startups to thriving enterprises. We take immense pride in the unwavering trust of our satisfied clients, and it is our commitment to excellence that sets us apart.
                Our journey is one of innovation and early adoption of cutting-edge technologies, spanning the realms of cloud and mobile applications, as well as bespoke software development. We've left our indelible mark across diverse domains, including e-commerce, healthcare, insurance, education, accounting, content management systems, ERP solutions, customer relationship management systems, and the dynamic energy sector.
                We don't just deliver projects; we craft experiences that redefine industries. Join us on this exhilarating journey towards technological transformation and business success. At RESHNAKS INFOTECH, we don't follow trends; we create them.
              </p>
            </div>
          </div>
        </section>
        <h1 className='my-5'>Each Coder In Our Team works to</h1>
        <section className="twoo mt-1 mb-1">
          <div className="leftt">
            <h2>Deliver User Centric Design</h2>
            <h6 className='m-5'>At our core, we are committed to crafting user-centric experiences that transcend expectations. Our dedicated team relentlessly focuses on the minutest user needs. We aspire to build a seamless bridge between science and design, creating intuitive solutions that effortlessly adapt to any medium or platform. In a world of complexity, we champion simplicity, ensuring our designs resonate with clarity and purpose. Welcome to a future where user satisfaction is not just a goal; it's a guarantee.</h6>
          </div>
          <div className="rightt">
            <h2>Deliver excellence in time and quality</h2>
            <h6 className="m-5">Our unwavering commitment: Delivering excellence punctually and with uncompromising quality. We believe that time is as precious as the work we produce. Every project, every client, is an opportunity to demonstrate our dedication to delivering top-tier results, precisely when you need them.</h6>
          </div>
        </section>
        <div className="cd">
        <h1 className='my-3 mb-5'>Founder and Co-Founder</h1>
        <div className='kg d-flex mb-5'>
            <div className="card d-flex mx-5" style={{ "width": "20rem" }}>
            <h3>Founder</h3>
            <img src="kw.jpeg" className="card-img-top" alt="" />
            <div className="card-body">
              <h5 className="card-title">Kshitiz Sharma</h5>
              <p className="card-text">Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work.</p>
            </div>
          </div>
          <div className="card d-flex" style={{ "width": "20rem", "marginLeft": "460px" }}>
            <h3>Co-Founder</h3>
            <img src="sh.jpeg" className="card-img-top" alt="" />
            <div className="card-body">
              <h5 className="card-title">Shailesh Sharma</h5>
              <p className="card-text">The biggest risk is not taking any risk. In a world that is changing quickly, the only strategy that is guaranteed to fail is not taking risks.</p>
            </div>
          </div>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
