import React, { useRef } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import emailjs from '@emailjs/browser';
import './Contact.css'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_wa4g0oi', 'template_u5v88ia', form.current, 'RKz_scifofvnyPHpf')
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <Navbar />
      <div className='container' id='mainco'>

        <section className="mb-4">
          <h2 className="h1-responsive font-weight-bold text-center my-4" style={{"marginLeft":"-0.9em"}}>Contact us</h2>
          <p className="text-center w-responsive mx-auto mb-5">
            Do you have any question? Please do not hesitate to contact us directly. Our team will come back to you within
            a matter of hours to help you.
          </p>

          <div className="row">
            <div className="col-md-9 mb-md-0 mb-5 " style={{"marginLeft":"37px"}} >
              <form id="contact-form" name="contact-form" ref={form} onSubmit={sendEmail}>
                <div className="row" >
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input type="text" id="name" name="name" className="form-control" />
                      <label htmlFor="name" className="">
                        Your name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input type="text" id="email" name="email" className="form-control" />
                      <label htmlFor="email" className="">
                        Your email
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="md-form mb-0">
                      <input type="text" id="subject" name="subject" className="form-control" />
                      <label htmlFor="subject" className="">
                        Subject
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="md-form">
                      <textarea
                        type="text"
                        id="message"
                        name="message"
                        rows="2"
                        className="form-control md-textarea"
                      ></textarea>
                      <label htmlFor="message">Your message</label>
                    </div>
                  </div>
                </div>
                <div className="text-center text-md-left">
                  <button className="btn btn-primary" type="submit" value="Send">
                    Send
                  </button>
                </div>
              </form>
              <div className="status"></div>
            </div>
            {/* <div className="col-md-3 text-center">
              <ul className="list-unstyled mb-0">
                <li>
                  <i className="fas fa-map-marker-alt fa-2x"></i>
                  <p>Ahmedabad, Gujarat, India</p>
                </li>
                <li>
                  <i className="fas fa-phone mt-4 fa-2x"></i>
                  <p>+91 88102 78084</p>
                </li>
                <li>
                  <i className="fas fa-envelope mt-4 fa-2x"></i>
                  <p>SHRENAKSinfotech@gmail.com</p>
                </li>
              </ul>
            </div> */}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
