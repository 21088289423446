import React from 'react'
import './Navbar.css'
import {Link} from 'react-router-dom';
const Navbar = () => {
  return (
    <div>
      

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <div className="container-fluid">
    <Link className="navbar-brand" to="/"><img src="logo-color.png" alt="logo" id='logo'/></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <span  style={{"fontStyle":"italic","textDecoration":"underline", "color":"white"}}>Coding dreams into reality</span>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/">Home</Link>
        </li>
        
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/services">Services</Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/careers">Careers</Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/about">About Us</Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/contact">Contact Us</Link>
        </li>

        {/* <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/"><button type="button" class="btn btn-success" id='getquo'>Get Quote</button></Link>
        </li> */}


      </ul>
    </div>
  </div>
</nav>



    </div>
  )
}

export default Navbar
