import React from 'react';
import Navbar from '../components/Navbar';
import './Home.css';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WebIcon from '@mui/icons-material/Web';
import AppsIcon from '@mui/icons-material/Apps';
import ArticleIcon from '@mui/icons-material/Article';
import DataArrayIcon from '@mui/icons-material/DataArray';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import Footer from '../components/Footer';
import Contactt from '../components/Contactt';
import vid from '../images/CV(2).mp4';

const Home = () => {
  return (
    <div>
      <Navbar />
        
      <div className="container vid">
      <video style={{  "width": "100%","margin":"0px","backgroundSize":"cover" }} autoPlay loop muted>
            <source src={vid} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        
        <section className="one">
          <div className="left">
            <h1 style={{ fontSize: '40px', textAlign: 'start', marginTop: '20px' }}>
              "We imbue each project with a personal touch, infusing it with our distinct blend of creativity and efficiency. Our commitment to excellence ensures that we consistently deliver remarkable results."
            </h1>
            <span style={{ textAlign: 'start', marginTop: '20px' }}>
              "From global industry leaders to pioneering startups, we forge enduring partnerships with our clients, guiding them on a transformative journey. Together, we breathe life into businesses with visionary design, user-centric experiences that transcend expectations, and groundbreaking development that defines the future."
            </span>
            <Link to="/services">
                <button type="button" className="btn m-3" id="serv">
                SEE WHAT WE DO <ArrowForwardIcon />
              </button>
            </Link>
          </div>
          <div className="right">
            <img src="head-right.jpg" alt="" />
          </div>
        </section>
        <section className="two">
          <h2>Unlock Exceptional Growth Potential</h2>
          <h2>Discover Our Diverse Array of Expertly Crafted Solutions</h2>
          <span style={{ display: 'flex', alignItems: 'center', marginRight: '290px', marginTop: '20px', textAlign: 'center' }}>
            Explore a spectrum of tailored services meticulously designed to elevate your business to new heights. From cutting-edge technology to strategic consulting, our offerings are the driving force behind your journey to unparalleled success.
          </span>
          <div className="container se" style={{"marginLeft":"50px"}}>
            <div className="row align-items-start my-4">
              <div className="col my-2" style={{ textAlign: 'start' }}>
                <WebIcon style={{ fontSize: '50px' }} />
                <span style={{ fontSize: '20px', textAlign: 'start', marginTop: '40px', marginLeft: '20px', fontWeight: 'bolder' }}>
                  Website Development and UI/UX Design
                </span>
                <p style={{ width: '360px', textAlign: 'start', marginLeft: '70px' }}>
                  Our mission is to transform your vision into an extraordinary digital experience, blending the power of creativity with technical excellence. With stunning visuals and flawless functionality, we don't just build websites; we create online platforms that resonate with your brand, leaving an indelible mark on your audience.
                </p>
              </div>
              <div className="col my-2" style={{ textAlign: 'start' }}>
                <AppsIcon style={{ fontSize: '50px' }} />
                <span style={{ fontSize: '20px', textAlign: 'start', marginTop: '40px', marginLeft: '20px', fontWeight: 'bolder' }}>
                  App Development
                </span>
                <p style={{ width: '360px', textAlign: 'start', marginLeft: '70px' }}>
                  Unlock your digital potential with cutting-edge app development. Our team creates intuitive, high-performance apps that transform ideas into reality. Crafted for success, designed for you.
                </p>
              </div>
            </div>
            <div className="row align-items-start d-flex my-4">
              <div className="col my-2" style={{ textAlign: 'start' }}>
                <ArticleIcon style={{ fontSize: '50px' }} />
                <span style={{ fontSize: '20px', textAlign: 'start', marginTop: '40px', marginLeft: '20px', fontWeight: 'bolder' }}>
                  Resume Building
                </span>
                <p style={{ width: '360px', marginLeft: '70px', textAlign: 'start' }}>
                  Crafting Resumes That Leave a Lasting Impression: Our dedicated team of experts excels in the art of curating personalized resumes that not only highlight your skills, experience, and accomplishments but also captivate potential employers. With an unwavering commitment to precision, we craft resumes that transcend the ordinary, leaving an indelible mark on your professional journey.
                </p>
              </div>
              <div className="col my-2" style={{ textAlign: 'start' }}>
                <DataArrayIcon style={{ fontSize: '50px' }} />
                <span style={{ fontSize: '20px', textAlign: 'start', marginTop: '40px', marginLeft: '20px', fontWeight: 'bolder' }}>
                  Data Entry
                </span>
                <p style={{ width: '360px', textAlign: 'start', marginLeft: '70px' }}>
                  Our dedicated team of design virtuosos is committed to crafting interfaces that transcend the ordinary. We've made it our mission to captivate and enchant users through intuitive, user-centric design principles. With meticulous attention to detail, we don't just create digital products that look good; we engineer experiences that users will love. From the inception of wireframes to the grand reveal of final prototypes, our journey is a relentless pursuit of excellence in every pixel and interaction.
                </p>
              </div>
            </div>
            <div className="row align-items-start my-4">
              <div className="col my-2" style={{ textAlign: 'start' }}>
                <AddBusinessIcon style={{ fontSize: '50px' }} />
                <span style={{ fontSize: '20px', textAlign: 'start', marginTop: '40px', marginLeft: '20px', fontWeight: 'bolder' }}>
                  Digital Marketing
                </span>
                <p style={{ width: '360px', textAlign: 'start', marginLeft: '70px' }}>
                  We provide a comprehensive range of digital marketing solutions to boost your online presence, drive traffic, and convert visitors into loyal customers. Let's take your brand to new heights in the digital realm.
                </p>
              </div>
              <div className="col my-2" style={{ textAlign: 'start' }}>
                <DesignServicesIcon style={{ fontSize: '50px' }} />
                <span style={{ fontSize: '20px', textAlign: 'start', marginTop: '40px', marginLeft: '20px', fontWeight: 'bolder' }}>
                  Graphic Designing
                </span>
                <p style={{ width: '360px', textAlign: 'start', marginLeft: '70px' }}>
                  Elevate your brand with captivating graphics that tell your story. Our design experts are here to transform your ideas into stunning visuals that leave a lasting impression. Explore our graphic design services and let your brand's personality shine through every pixel.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="three">
          <h1>Why RESHNAKS INFOTECH? What we offer:</h1>
          <h3 style={{ marginTop: '20px' }}>RESHNAKS INFOTECH: Your Gateway to Success</h3>
          <br />
          <span style={{ fontSize: '18px' }}>
            At RESHNAKS INFOTECH, we offer a spectrum of cutting-edge solutions tailored to drive your business forward.
            <br />
            At RESHNAKS INFOTECH, we're not just a service provider; we're your partner in growth and innovation. Discover how we can help you succeed.
          </span>
        </section>
        <div className="container my-5 d-flex flex-wrap justify-content-around">
          <div class="card my-2" style={{ width: '18rem' }}>
            <img src="cl.jpg" class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">24x7 Service</h5>
              <p class="card-text">
                Experience a steadfast commitment to your success with our round-the-clock support. At RESHNAKS INFOTECH, we're more than a service provider; we're your 24/7 partner in excellence. No matter where you are, we're always here to ensure your needs are met, empowering your journey to success.
              </p>
            </div>
          </div>
          <div class="card my-2" style={{ width: '18rem' }}>
            <img src="sec.jpg" class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Fortified Security and Unshakable Stability</h5>
              <p class="card-text">
                Our unwavering dedication to ironclad security and rock-solid stability ensures your complete peace of mind. With us, your digital assets are safeguarded by the fortress of cutting-edge protection, allowing you to navigate the digital landscape with confidence.
              </p>
            </div>
          </div>
        
        
              <div class="card my-2" style={{ width: '18rem' }}>
            <img src="fs.jpg" class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Blazingly Fast</h5>
              <p class="card-text">
                Experience the exhilarating velocity that propels your journey forward with us. At Sarane Infotech, we've harnessed the power of cutting-edge technology and streamlined processes to deliver an unrivaled level of swiftness. Our commitment to speed isn't just about saving time; it's about maximizing your potential, meeting deadlines with ease, and staying ahead of the competition.
              </p>
            </div>
          </div>
          <div class="card my-2" style={{ width: '18rem' }}>
            <img src="inte.jpg" class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title" style={{"color":"black"}}>Effortless Synergy</h5>
                <p class="card-text" style={{"color":"black"}}>
                Our system effortlessly harmonizes with your existing tools and processes, amplifying efficiency and optimizing your workflow. At Sarane Infotech, we understand the importance of seamless integration in today's dynamic business landscape. Our solutions are not silos; they're strategic partners that adapt, connect, and empower your operations, fostering a coherent and agile environment that breeds success.
              </p>
            </div>
          
          </div>
        </div>
      </div>
      <Contactt className='cont'/>
      <Footer />
    </div>
  );
};

export default Home;
