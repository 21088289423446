import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const Careers = () => {
  return (
    <div>
        <Navbar/>
        <div className="container m-5 con">
            <h1>Currently we are hiring for different positions</h1>
            <h2>To apply mail your resume at</h2>
            <h5>hr@reshnaks.com</h5>
        </div>
        <Footer/>
    </div>
  )
}

export default Careers