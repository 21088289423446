import React,{useState,useEffect} from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ActionAreaCard from '../components/ActionAreaCard'
import axios from 'axios';

const Services = () => {

    const apiUrl = "Servicedata.json";

    const [data, setData] = useState([]);

    useEffect(() => {
        // Replace 'YOUR_API_URL' with the actual API endpoint you want to fetch
        axios.get(apiUrl)
          .then((response) => {
            setData(response.data); // Update the state with fetched data
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }, []);

  return (
    <div>
      <Navbar/>
      <div className="container my-5 d-flex justify-content-around flex-wrap">
      


      {data.map((item)=>(
 <ActionAreaCard key={item.id} title={item.title} icon={item.icon} description={item.description} />
            ))}
       
      </div>
      <Footer/>
    </div>
  )
}

export default Services
