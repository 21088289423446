import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import "./Area.css"

export default function ActionAreaCard({icon,title,description}) {

  
  return (
    <div className="m-4">
      <Link to='/services' style={{ textDecoration: 'none' }}>
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
         image={icon}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" >
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <button
              type="button"
              className="btn btn-dark px-5 mb-3 btn-hover" // Add the btn-hover class
              data-mdb-toggle="button"
              autoComplete="off"
            >Explore</button>
      </CardActionArea>
    </Card>
    </Link>
    </div>
  );
}